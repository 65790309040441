<script>
export default {
  metaInfo: {
    title: "MSISDN Details",
  }
};
</script>

<script setup>
import DynamicMsisdnsView from '@/components/dynamic/MsisdnsView.vue';
</script>

<template>
  <DynamicMsisdnsView/>
</template>

<style scoped>

</style>