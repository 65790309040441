import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_COCKPIT_SIM_SERVICE}/cockpit-sim-service`);
    // importSims: 'import/sims',

const cockpitSimService = {
    getMsisdns(params, then, error) {
        service.getRequest(
            1, 'msisdns',
            params,
            then,
            error,
        );
    },
    createMsisdn(msisdn, then, error) {
        service.putRequest(
            1, 'msisdn',
            msisdn,
            then,
            error,
        );
    },
    readMsisdn(id, version, then, error) {
        service.getRequest(
            1,
            'msisdn/{id}/{version}',
            null,
            then,
            error,
            id,
            version,
        );
    },
    updateMsisdn(msisdn, then, error) {
        service.patchRequest(
            1, 'msisdn/{id}',
            msisdn,
            then,
            error,
            msisdn.id,
        );
    },
    deleteMsisdn(id, then, error) {
        service.deleteRequest(
            1, 'msisdn/{id}',
            null,
            then,
            error,
            id,
        );
    },
    getMsisdnIds(then, error) {
        service.getRequest(
            1, 'msisdns/ids',
            null,
            then,
            error,
        );
    },
    getSims(params, then, error) {
        service.getRequest(
            1, 'sims',
            params,
            then,
            error,
        );
    },
    createSim(sim, then, error) {
        service.putRequest(
            1, 'sim',
            sim,
            then,
            error,
        );
    },
    readSim(id, version, then, error) {
        service.getRequest(
            1,
            'sim/{id}/{version}',
            null,
            then,
            error,
            id,
            version,
        );
    },
    updateSim(sim, then, error) {
        service.patchRequest(
            1,
            'sim/{id}',
            sim,
            then,
            error,
            sim.id,
        );
    },
    deleteSim(id, then, error) {
        service.deleteRequest(
            1, 'sim/{id}',
            null,
            then,
            error,
            id,
        );
    },
    getSimCountByTagQuery(tagQuery, then, error) {
        service.getRequest(
            2, 'sims/count',
            { "tag-query": tagQuery },
            then,
            error,
        );
    },
    getTags(then, error) {
        service.getRequest(
            2, 'tags',
            null,
            then,
            error,
        );
    }
};

export default cockpitSimService;
